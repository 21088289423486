<template>
  <Header />
  <div class="exhibition" id="start">
    <section class="exhibition-title">
      <template v-if="lang==='de'">
        <div class="title">
          <div class="line-1">Über</div>
          <div class="line-2">setzen</div>
          <div class="line-3">ist Macht</div>
          <div class="line-4">Geheimnisse</div>
          <div class="line-5">Geschenke</div>
          <div class="line-6">Geschichten</div>
          <div class="line-7">in der</div>
          <div class="line-8">Frühen</div>
          <div class="line-9">Neuzeit</div>
        </div>
      </template>
      <template v-if="lang==='en'">
        <div class="title">
          <div class="line-1">Trans</div>
          <div class="line-2">lation</div>
          <div class="line-3">is Power</div>
          <div class="line-4">Secrets</div>
          <div class="line-5">Gifts</div>
          <div class="line-6">Stories</div>
          <div class="line-7">in the</div>
          <div class="line-8">Early Modern</div>
          <div class="line-9">Period</div>
        </div>
      </template>
      <div class="title-background">
        <!-- <img src="../assets/images/title_bg_02.svg" > -->
        <img class="sb-1" src="../assets/images/Kugel_Geheimnisse.svg" >
        <img class="sb-2" src="../assets/images/Kugel_Geschenke.svg" >
        <img class="sb-3" src="../assets/images/Kugel_Geschichten.svg" >
      </div>
      <div v-if="!audioPlaying" class="icon play-audio clickable" @click="playAudio()">
        <img src="../assets/icons/volume-high.svg" alt="Video schließen">
      </div>
      <div v-if="audioPlaying" class="icon play-audio clickable" @click="stopAudio()">
        <img src="../assets/icons/volume_off.svg" alt="Video schließen">
      </div>
      <audio ref="tonwolke">
          <source src="0_0_Tonwolke BEL.mp3" type="audio/mpeg">
      </audio>
    </section>
    <section class="general-intro">
      <div class="text-content"
        v-html="translate(homepage, 'content')"
      />
    </section>
    <section class="curators-intro" id="curatorials">
      <div class="curatorial"
        v-for="(curatorial, index) in curatorials"
        :key="`curatorial-${index}`"
      >

        <video
          width="384"
          :id="`curatorial-video-${index}`"
          :ref="el => { if (el) videos[index] = el }"
          controls muted loop
        >
          <source :src="curatorial.url" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="overlay"
          @click="activateVideo(index)"
          v-if="activatedVideo !== index"
        >
          <img src="../assets/icons/volume-high.svg" alt="listen to video">
        </div>
      </div>
      
    </section>
    <div class="separator"></div>
    <div class="stations-container" ref="stationsContainer">
      <section class="station"
        v-for="(station, sIndex) in stations"
        :key="`station-${sIndex}`"
        :data-id="`station-${sIndex}`"
        :id="`station-${sIndex}`"
        :ref="station => { if (station) stationSections[sIndex] = station }"
      >
        <div class="station-intro">
          <h2>{{ translate(station.content, 'title') }}</h2>
          <div class="text-content"
            v-html="translate(station.content, 'intro')"
          />
        </div>
        <div class="exhibits">
          <div class="exhibit"
            v-for="(exhibit, eIndex) in station.exhibits"
            :key="`station-${sIndex}-exhibit-${eIndex}`"
            :id="`station-${sIndex}-exhibit-${eIndex}`"
            :ref="el_exhibit => { if (el_exhibit) exhibitElements[getExhibitIndex(sIndex,eIndex)] = el_exhibit }"
          >
            <div class="exhibit-medium">
              <h3 class="single-column">{{ translate(exhibit.content, 'title') }}</h3>
              <div class="image-container">
                <!-- Normales Exponat -->
                <template
                  v-if="!exhibit.content.map.url"
                >
                  <!-- wenn normales Bild -->
                  <template v-if="!exhibit.content.video_still">
                    <img
                      class="repro"
                      :id="`image-${sIndex}-${eIndex}`"
                      :src="getImage(exhibit)"
                      :style="imgDimStyles()"
                      @click="showImageModal(
                        exhibit,
                        `station-${sIndex}-exhibit-${eIndex}`)"
                    >
                    <div class="icon clickable"
                      @click="showImageModal(
                          exhibit,
                          `station-${sIndex}-exhibit-${eIndex}`)"
                    >
                      <img
                        src="../assets/icons/zoom_in_black_24dp.svg"
                        alt="öffnet Großansicht der Abbildung"
                      >
                    </div>
                  </template>
                  <!-- wenn ein Video-Still (direkt zum Vertiefungsvideo) -->
                  <template v-if="exhibit.content.video_still && getVideo(exhibit)">
                    <img
                      class="repro"
                      :id="`image-${sIndex}-${eIndex}`"
                      :src="getImage(exhibit)"
                      :style="imgDimStyles()"
                      @click="showVideoModal(getVideo(exhibit))"
                    >
                    <div class="icon clickable"
                      @click="showImageModal(
                          exhibit,
                          `station-${sIndex}-exhibit-${eIndex}`)"
                    >
                      <img
                        src="../assets/icons/zoom_in_black_24dp.svg"
                        alt="öffnet Großansicht der Abbildung"
                      >
                    </div>
                  </template>
                </template>
                <!-- interaktive Karte -->
                <template
                  v-if="exhibit.content.map.url"
                >
                  <img
                    :src="getMap(exhibit)"
                    :style="imgDimStyles()"
                    @click="showMapModal(
                        exhibit,
                        `station-${sIndex}-exhibit-${eIndex}`,
                        station)"
                  >
                  <div class="icon clickable"
                    @click="showMapModal(
                        exhibit,
                        `station-${sIndex}-exhibit-${eIndex}`,
                        station)"
                  >
                    <img
                      src="../assets/icons/zoom_in_black_24dp.svg"
                      alt="öffnet Großansicht der Abbildung"
                    >
                  </div>
                </template>
              </div>
              <div class="caption single-column" v-html="translate(exhibit.content, 'caption')" />
            </div>
            <div class="exhibit-text">
              <h3 class="double-column">{{ translate(exhibit.content, 'title') }}</h3>
              <div v-html="translate(exhibit.content, 'comment')" />
              <div class="caption double-column" v-html="translate(exhibit.content, 'caption')" />
              <div class="extra-level"
                v-if="getVideo(exhibit)"
                @click="showVideoModal(getVideo(exhibit))"
              >
                <img src="../assets/icons/Vertiefung.svg" alt="">
                <div class="hint">Video</div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </section>
    </div>
    <section class="statements" id="statements">
      <div class="statement"
        v-for="(statement, index) in statements"
        :key="`statement-${index}`"
      >
        <video
          width="384"
          :id="`statement-video-${index}`"
          :ref="el => { if (el) statementVideos[index] = el }"
          controls autoplay muted loop
        >
          <source :src="statement.url" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="overlay"
          @click="activateStatementVideo(index)"
          v-if="activatedStatementVideo !== index"
        >
          <img src="../assets/icons/volume-high.svg" alt="listen to video">
        </div>
      </div>
    </section>
    <section class="guest-book" id='guest-book'>
      <div class="guest-book-header clickable" @click="toggleGuestBook">
        <h2>{{ translateGBElement('title') }}</h2>
        <img src="../assets/icons/expand_more_black_24dp.svg" alt="show guest book" :class="{'reduce': showGuestBook}">
      </div>
      <div class="guest-book-body" v-if="showGuestBook">
        <p>{{ translateGBElement('greeting') }}</p>
        <form action="#" @submit.prevent="submitGuestBookEntry" v-if="showForm">
          <div class="form-element">
            <label for="name" class="form-label">{{ translateGBElement('label_name') }}</label>
            <input
              id="name"
              type="text"
              class="form-control"
              required
              v-model="comment.name"
            />
          </div>
          <div class="form-element">
            <label for="email" class="form-label">{{ translateGBElement('label_email') }}</label>
            <input
              id="email"
              type="email"
              class="form-control"
              required
              v-model="comment.email"
            />
          </div>
          <div class="form-element website">
            <label for="website" class="form-label">Website</label>
            <input
              id="website"
              type="url"
              class="form-control"
              placeholder="https://example.com"
              tabindex="-1"
              autocomplete="off"
              v-model="comment.website"
            />
          </div>
          <div class="form-element">
            <label for="comment" class="form-label">{{ translateGBElement('label_comment') }}</label>
            <textarea
              class="form-control comment"
              id="comment"
              rows="5"
              required
              v-model="comment.comment"
            ></textarea>
          </div>
          <div class="form-element">
            <button class="submit-button clickable" type="submit">{{ translateGBElement('label_commit') }}</button>
          </div>
        </form>
        <div class="comments" v-if="comments.length">
          <h2>{{ translateGBElement('header_comments')}}</h2>
          <div class="comment"
            v-for="(comment, index) in comments"
            :key="`comment-${index}`"
          >
            <div class="comment-content"
              v-html="formatComments(comment.comment)"
            />
            <div class="comment-author">
              {{ comment.name }} ({{ formatDate(comment.datecreated) }})
            </div>
          </div>
        </div>
        <div class="thank-you-message"
          v-if="showThankYou"
          v-html="translateGBElement('confirmation')"
        />
      </div>
    </section>
    <section class="resources" id="resources">
      <div class="resources-grid">
        <div class="left-col">
          <div class="text-content"
            v-html="translate(homepage, 'colophon')"
          />
          <div class="logos">
            <div class="logo-container double">
              <a href="https://www.spp2130.de/" target="_blank">
                <img class="spp" src="../assets/logos/SPP2130_Logo_4C_cr.svg" alt="Logo des SPP 2130">
              </a>
              <a href="https://www.dfg.de/" target="_blank">
                <img class="dfg" src="../assets/logos/dfg_logo_englisch_blau_en_4c.svg" alt="Logo der Deutschen Forschungsgemeinschaft">
              </a>
            </div>
            <div class="logo-container single">
              <a href="https://open.spotify.com/show/4JzS22Q5CPjUgcfk8ufyFV" target="_blank">
                <img class="hab" src="../assets/logos/Kapselwurf_Logo.png" alt="Logo von Kapselwurf">
              </a>
              <template v-if="lang === 'de'">
                <p>Podcast der TransUnit Kapselwurf<br />
                <a href="https://open.spotify.com/show/4JzS22Q5CPjUgcfk8ufyFV" target="_blank">bei Spotify hören ...</a></p>
              </template>
              <template v-if="lang === 'en'">
                <p>Listen to the podcast of the TransUnit Kapselwurf<br />
                <a href="https://open.spotify.com/show/4JzS22Q5CPjUgcfk8ufyFV" target="_blank">bei Spotify hören ...</a></p>
              </template>
            </div>
            <div class="logo-container single">
              <a href="https://www.hab.de/category/bibliothek/kataloge-und-digitale-bibliothek/digitale-bibliothek/" target="_blank">
                <img class="hab" src="../assets/logos/HAB_Logo.svg" alt="Logo der HAB">
              </a>
              <template v-if="lang === 'de'">
                <p>In der Wolfenbütteler Digitalen Bibliothek finden Sie digitalisierte Bestände unserer Kooperationspartnerin, der Herzog August Bibliothek Wolfenbüttel<br />
                <a href="https://www.hab.de/category/bibliothek/kataloge-und-digitale-bibliothek/digitale-bibliothek/" target="_blank">zur Sammlung ...</a>
              </p>
              </template>
              <template v-if="lang === 'en'">
                <p>In the Wolfenbüttel Digital Library you may find digitised holdings of our cooperation partner, the Herzog August Bibliothek Wolfenbüttel<br />
                <a href="https://www.hab.de/category/bibliothek/kataloge-und-digitale-bibliothek/digitale-bibliothek/" target="_blank">to the library ...</a>
              </p>
              </template>
            </div>
          </div>
          <div class="text-content"
            v-html="translate(homepage, 'resources')"
          />
        </div>
        <div class="right-col">
          <div class="text-content"
            v-html="translate(homepage, 'credits')"
          />
        </div>
      </div>
    </section>
    <section class="imprint" id="imprint">
      <div class="resources-grid">
        <div class="left-col">
          <div class="text-content"
            v-html="translate(homepage, 'imprint')"
          />
        </div>
        <div class="right-col">
          <div class="text-content"
            v-html="translate(homepage, 'privacy')"
          />
        </div>
      </div>
    </section>
    <!-- Badge -->
    <div class="guest-book-badge clickable"
      @click="openGuestBook()"
    >
      Gästebuch
    </div>
    <!-- Modals -->
    
    <teleport
      to=".modal"
      v-if="modalState || modalVideoState || modalMapState"
    >
      <template v-if="modalState">
        <ImageModal
          :content="modalContent"

        />
      </template>
      <template v-if="modalVideoState">
        <VideoModal
          :content="modalVideoContent"

        />
      </template>
      <template v-if="modalMapState">
        map
        <MapModal
          :content="modalMapContent"

        />
      </template>
    </teleport>
  </div>
</template>

<script>
import { computed, ref, reactive, onMounted, onBeforeUpdate } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useApiContent from '@/use/apiContent.js'
import useApiComments from '@/use/apiComments.js'
import useMaps from '@/config/maps.js'
import Header from '@/components/parts/Header.vue'
import ImageModal from '@/components/Image-modal'
import VideoModal from '@/components/Video-modal'
import MapModal from '@/components/Map-modal'

import axios from 'axios'

export default {
  name: 'Exhibition',
  components: {
    Header,
    ImageModal,
    VideoModal,
    MapModal
  },
  props: {

  },
  async setup() {
    const store = useStore()
    const router = useRouter()
    const lang = computed(() => {
      return store.state.lang
    })
    const audioPlaying = ref(false)
    function playAudio() {
      tonwolke.value.play()
      audioPlaying.value = true;
    }
    function stopAudio() {
      tonwolke.value.pause()
      audioPlaying.value = false;
    }
    const tonwolke = ref()

    const maps = useMaps

    const stationsContainer = ref()

    function imgDimStyles () {
      const maxHeight = store.state.viewport.subHeader * .95

      return `max-width: 95%; max-height: ${maxHeight}px;`
    }

    function translate(el, field) {
      let output = el[field + '_' + lang.value]
      // marker
      if(field === 'title' && !output) output = '[title missing!]'
      return output
    }
    function getImage(item) {
      return item.content.image.url || '/Dummy_01.png'
    }
    function getMap(item) {
      return item.content.map.url
    }
    function getVideo(item) {
      return item.content[`video_${lang.value}`].url
    }
    // curatorial videos:
    const activatedVideo = ref(null)
    const activatedStatementVideo = ref(null)
    const videos = ref([])
    const statementVideos = ref([])
    const stationSections = ref([])
    const exhibitElements = ref([])
    const currentStations = ref([])

    let exhibitIndex = 0

    function getExhibitIndex() {
      exhibitIndex += 1
      return exhibitIndex
    }

    function videoIntersectCallback(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          entry.target.pause()
        } else {
          entry.target.play()
        }
      })
    }
    function stationIntersectCallback(entries) {
      entries.forEach(entry => {
        const numId = parseInt(entry.target.id.replace('station-', ''))
        if (!entry.isIntersecting) {
          currentStations.value = [...currentStations.value].filter(s => s !== numId)
          document.getElementById(`station-nav-${numId}`).querySelector('img').classList.remove('active')
        } else {
          currentStations.value.push(numId)
          document.getElementById(`station-nav-${numId}`).querySelector('img').classList.add('active')
        }
      })
    }
    function stationsContainerIntersectCallback(entry) {
        if (!entry[0].isIntersecting) {
          document.querySelector('.nav').classList.add('non-stations')
          document.querySelector('.guest-book-badge').classList.add('non-stations')
        } else {
          document.querySelector('.nav').classList.remove('non-stations')
          document.querySelector('.guest-book-badge').classList.remove('non-stations')
        }
    }
    function exhibitIntersectCallback(entries) {

      entries.forEach(entry => {
        const stationNum = entry.target.id.split('-')[1]
        const exhibitNum = entry.target.id.split('-')[3]

        if (!entry.isIntersecting) {
          document.getElementById(`exhibit-marker-${stationNum}-${exhibitNum}`).classList.remove('active')
        } else {
          document.getElementById(`exhibit-marker-${stationNum}-${exhibitNum}`).classList.add('active')
        }
      })
    }
    function activateVideo(index) {
      activatedVideo.value = index
      videos.value.forEach((video, i) => {
        if (i === index) {
          video.muted = false
          video.currentTime = 0
        } else {
          video.muted = true
        }
      });
    }
    function activateStatementVideo(index) {
      activatedStatementVideo.value = index
      statementVideos.value.forEach((video, i) => {
        if (i === index) {
          video.muted = false
          video.currentTime = 0
        } else {
          video.muted = true
        }
      });
    }

    onMounted(() => {
      const videoOptions = {
        rootMargin: '-112px 0px 0px',
        threshold: 1.0
      }
      const stationOptions = {
        rootMargin: '-112px 0px 0px',
        threshold: 0
      }
      const stationsContainerOptions = {
        rootMargin: '-112px 0px 0px',
        threshold: 0
      }
      const exhibitOptions = {
        rootMargin: '-112px 0px 0px',
        threshold: 0
      }
      let videoObserver = new IntersectionObserver(videoIntersectCallback, videoOptions)
      videos.value.forEach(entry => {
        videoObserver.observe(entry)
      })
      statementVideos.value.forEach(entry => {
        videoObserver.observe(entry)
      })
      let stationObserver = new IntersectionObserver(stationIntersectCallback, stationOptions)
      stationSections.value.forEach(entry => {
        stationObserver.observe(entry)
      })
      let exhibitObserver = new IntersectionObserver(exhibitIntersectCallback, exhibitOptions)
      exhibitElements.value.forEach(entry => {
        exhibitObserver.observe(entry)
      })
      stationsContainer.value = document.querySelector('.stations-container')
      let stationsContainerObserver = new IntersectionObserver(stationsContainerIntersectCallback, stationsContainerOptions)
      stationsContainerObserver.observe(stationsContainer.value)
      if(tonwolke.value) {
        tonwolke.value.addEventListener('ended', () => {
          audioPlaying.value = false
        })
      }
    })

    onBeforeUpdate(() => {
        videos.value = []
        stationSections.value = [],
        exhibitElements.value = []
      })
    // :videos

    const apiContent = await useApiContent()

    const curatorials = computed( () => {
      const input = apiContent.curatorials
      // Platzhalter für eventuelles Mapping
      const output = input

      return output
    })
    const statements = computed( () => {
      const input = apiContent.statements
      // Platzhalter für eventuelles Mapping
      const output = input

      return output
    })
    const homepage = computed( () => {
      return apiContent.homepage.content
    })
    const stations = computed( () => {
      return apiContent.stations
    })

    // Modal *********************************************************************************

    const modalContent = ref({})
    const modalMapContent = ref({})
    const modalVideoContent = ref(
      {
        video: null
      }
    )
    const modalState = computed(() => {
      return store.state.modalState
    })
    const modalVideoState = computed(() => {
      return store.state.modalVideoState
    })
    const modalMapState = computed(() => {
      return store.state.modalMapState
    })

    function toggleModal() {
      store.dispatch('toggleModal')
    }
    function toggleVideoModal() {
      store.dispatch('toggleVideoModal')
    }
    function toggleMapModal() {
      store.dispatch('toggleMapModal')
      console.log('nach dispatch: modalMapState', modalMapState.value)
    }

    function showImageModal(exhibit, exhibitId) {
      modalContent.value = {}
      modalContent.value.file = getImage(exhibit)
      modalContent.value.sequence = exhibit.content.image_sequence && exhibit.content.image_sequence.length
        ? exhibit.content.image_sequence.map(i => i.url)
        : undefined
      modalContent.value.caption = translate(exhibit.content, 'caption')
      const width = document.getElementById(exhibitId)
        .querySelector('img')
        .offsetWidth
      const height = document.getElementById(exhibitId)
        .querySelector('img')
        .offsetHeight
      modalContent.value.orientation = width >= height
        ? 'landscape'
        : 'portrait'
      toggleModal()
    }
    function showMapModal(exhibit, exhibitId, station) {
      modalMapContent.value = {}
      modalMapContent.value.stationColor = station
        ? station.content.color
        : undefined
      modalMapContent.value.file = getImage(exhibit)
      modalMapContent.value.pois = maps[exhibit.content.slug]
      modalMapContent.value.caption = translate(exhibit.content, 'caption')
      modalMapContent.value.pois_content = exhibit.content.map_pois
      const width = document.getElementById(exhibitId)
        .querySelector('img')
        .offsetWidth
      const height = document.getElementById(exhibitId)
        .querySelector('img')
        .offsetHeight
      modalMapContent.value.orientation = width >= height
        ? 'landscape'
        : 'portrait'
      toggleMapModal()
    }

    function showVideoModal(video) {
      modalVideoContent.value.video = video
      toggleVideoModal()
    }
    // <---- Modal

    // Guest Book
    const commentsData = await useApiComments()
    const comments = commentsData.comments.map(comment => comment.attributes)

    function formatComments(comment) {
      const from = /\n/gi
      const to = '<br>'
      const sanitizeFrom = /<br><br>|<br>\s<br>/gi
      const sanitizeTo = '<br'
      
      comment = comment.replace(from, to)
      comment = comment.replace(sanitizeFrom, sanitizeTo)
      return comment
    }

    const gbElements = {
      de: {
        title: 'Gästebuch',
        greeting: 'Wir freuen uns auf Ihren Kommentar zu dieser Ausstellung!',
        label_name: 'Name',
        label_email: 'E-Mail',
        label_comment: 'Ihr Kommentar',
        label_commit: 'Abschicken',
        confirmation: `
          <p>Vielen Dank für Ihre Rückmeldung. Dies ist ein moderiertes Gästebuch. Ihr Kommentar muss von unserer Redaktion freigegeben werden und ist in Kürze hier sichtbar.<br>Wir hoffen, dass Sie anregende Impulse gewinnen konnten, und würden uns freuen, wenn Sie diese Ausstellung weiterempfehlen!</p>`,
        header_comments: 'Kommentare'
      },
      en: {
        title: 'Guestbook',
        greeting: 'We look forward to your comments on this exhibition!',
        label_name: 'Name',
        label_email: 'Email',
        label_comment: 'Your Comment',
        label_commit: 'Send',
        confirmation: `
          <p>Many thanks for your feedback. This is a moderated guestbook. Your comment has to be checked by our editorial team and will be visible here shortly.<br>We hope you gained stimulating new impulses and would be pleased if you recommended our show to others!</p>`,
        header_comments: 'Comments'
      }
    }
    function translateGBElement(element) {
      return gbElements[lang.value][element]
    }
    function formatDate(d) {
      const year = d.substr(0,4)
      const month = d.substr(5,2)
      const day = d.substr(8,2)
      return `${day}.${month}.${year}`
    }
    const stationsOnScreen = computed(() => {
        return store.state.stationsOnScreen
    })

    const comment = reactive({
      name: "",
      email: "",
      website: null,
      comment: "",
    });

    function resetComment() {
        showForm.value = false;
        showThankYou.value = true;
        comment.name = "",
        comment.email = "",
        comment.website = null,
        comment.comment = ""
    }

    const showGuestBook = ref(false);
    const showForm = ref(false);
    const showThankYou = ref(false);

    function toggleGuestBook() {
      showForm.value = true
      showThankYou.value = false
      showGuestBook.value = !showGuestBook.value;
    }
    function openGuestBook() {
      showGuestBook.value = true;
      showForm.value = true;
      router.push('/#guest-book')
    }
    const msg = ref("");
    const msg_type = ref("");
    // const url = "http://localhost:9000";
    const url = "https://cms.uebersetzenistmacht.de/comment-api/index.php";

    async function submitGuestBookEntry() {
      msg_type.value = "alert-secondary";
      msg.value = "<div class='spinner-border' role='status'></div>";

      // Server will also check, so this is not strickly necessary...
      if (comment.website !== null) {
        msg_type.value = "alert-success";
        msg.value = "New comment added successfully.";
        return;
      }
      try {
        let result = await axios.post(url, comment);
        msg.value = result.data.message;
        if (result.data.status !== "success") {
          msg_type.value = "alert-danger";
        } else {
          msg_type.value = "alert-success";
          resetComment();
        }
      } catch (error) {
        if (error.response) {
          // console.log(error.response.data);
          msg.value = error.response.data;
        } else if (error.request) {
          // console.log(error.request);
          msg.value = "Could not process request.";
        } else {
          // console.log("Error", error.message);
          msg.value = "Error in setting up the request.";
        }
        msg_type.value = "alert-danger";
      }
    }

    return {
      // getTitlePartStyle,
      // titleParts,
      homepage,
      stations,
      curatorials,
      statements,
      videos,
      statementVideos,
      activatedStatementVideo,
      activateStatementVideo,
      activatedVideo,
      activateVideo,
      lang,
      translate,
      getImage,
      getVideo,
      imgDimStyles,
      // Modal
      modalState,
      modalVideoState,
      modalMapState,
      showImageModal,
      showVideoModal,
      showMapModal,
      modalContent,
      modalVideoContent,
      modalMapContent,
      toggleModal,
      maps,
      getMap,
      stationSections,
      exhibitElements,
      currentStations,
      getExhibitIndex,
      playAudio,
      stopAudio,
      audioPlaying,
      tonwolke,
      toggleGuestBook,
      comment, 
      showGuestBook,
      showForm,
      showThankYou,
      submitGuestBookEntry,
      msg,
      msg_type,
      openGuestBook,
      translateGBElement,
      comments,
      formatComments,
      formatDate,
      stationsOnScreen
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/vars.scss';

.guest-book-badge {
  position: fixed;
  top: 18rem;
  right: 0px;
  width: 3rem;
  background: #fff;
  z-index: 100.000;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  writing-mode: vertical-lr;
  line-height: 1.4rem;
  padding: .5rem .5rem .5rem .5rem;
  font-family: "bluu", serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  &.non-stations {
    display: none;
  }
}

.exhibition {
  // max-width: 100vw;
  z-index: 1;
  margin: 7rem 0rem 2rem;
  font-size: 1.75rem;
  line-height: 2.4rem;
  .exhibition-title {
    position: relative;
    padding: 4rem;
    max-width: 90vw;
    // border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 100px);
    // overflow: hidden;
    .icon {
        position: absolute;
        width: 3.5rem;
        height: 3.5rem;
        background-color: $icon-bg;
        border: 1px solid $icon-border;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        z-index: 2;
        opacity: .75;
        &:hover {
            opacity: 1;
        }
        img {
            display: block;
            width: 1.75rem;
        }
        &.play-audio {
            top: 4rem;
            right: 4rem;
        }
    }
    .title-background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -1;
      display: none;
      @media screen and (min-width: $large) {
        display: block;
      }
      img {
        display: block;
        // height: 100%;
        // animation: title-bg ease-in forwards;
        opacity: 1;
        transform: scale(0);
        position: absolute;
        &.sb-1 {
          width: 360px;
          animation: sb-1-animation ease-in forwards;
          animation-delay: .6s;
          animation-duration: 3.9s;
          left: calc(50% - 360px);
          top: calc(50% - 180px);
        }
        &.sb-2 {
          width: 320px;
          animation: sb-2-animation ease-in forwards;
          animation-delay: .3s;
          animation-duration: 3.6s;
          left: calc(50% + 75px);
          top: calc(50% - 270px);
        }
        &.sb-3 {
          width: 300px;
          animation: sb-2-animation ease-in forwards;
          animation-delay: .8s;
          animation-duration: 3.8s;
          left: calc(50% + 30px);
          top: calc(50%);
        }
        @media screen and (max-width: $desktop) {
        }
        @media screen and (max-width: $large) {
        }
        @media screen and (max-width: $medium) {
          height: 80%;
        }
        @media screen and (max-width: $small) {

        }
      }
    }
    .title {
      display: inline-block;
      font-family: "bluu", serif;
      font-size: 2.75rem;
      text-transform: uppercase;
      // border: 1px solid green;
      @media screen and (max-width: $desktop) {
        font-size: 2.25rem;
        background-size: 400px;
        background-repeat: no-repeat;
        background-position-x: 20px;
        background-position-y: center;
      }
      @media screen and (max-width: $large) {
        font-size: 2.0rem;
        background-size: 320px;
        background-repeat: no-repeat;
        background-position-x: 20px;
        background-position-y: center;
      }
      @media screen and (max-width: $medium) {
        font-size: 1.8rem;
        background-size: 280px;
        background-repeat: no-repeat;
        background-position-x: 0;
        background-position-y: center;
      }
      @media screen and (max-width: $small) {
        font-size: 1.6rem;
        background-size: 250px;
        background-repeat: no-repeat;
        background-position-x: 0;
        background-position-y: center;
      }
      & > div {
        line-height: 110%;
        @media screen and (max-width: $desktop) {
        }
        @media screen and (max-width: $large) {
        }
        @media screen and (max-width: $medium) {
        }
        @media screen and (max-width: $small) {

        }
        // border: 1px solid pink;
        &.line-1 {
          padding-left: 2.25rem;
        }
        &.line-2 {
          padding-left: 2.0rem;
        }
        &.line-3 {
          padding-left: 3.5rem;
        }
        &.line-4 {
          padding-left: .25rem;
        }
        &.line-5 {
          padding-left: 1.75rem;
        }
        &.line-6 {
          text-indent: .25rem;
        }
        &.line-7 {
          text-indent: 0rem;
        }
        &.line-8 {
          padding-left: 2.25rem;
        }
        &.line-9 {
          padding-left: 2.75rem;
        }
      }
      
    }
  }
  h6 {
    color: red;
    visibility: hidden;
  }
  section {
    max-width: $main-max;
    margin: 0 auto;
    padding: 1rem;
    margin-bottom: $section-gap;
    @media screen and (max-width: $medium) {
      max-width: none;
    }
    &.curators-intro,
    &.statements {
      max-width: 900px;
    }
    h2 {
      margin-bottom: 1.5rem;
    }
    &.curators-intro,
    &.statements,
    &.station {
      margin: 6rem auto 6rem;
      padding-bottom: 6rem;
      // border-bottom: 1px solid #333;
    }
    &.station {
      max-width: 100%;
      font-size: 1.4rem;
      line-height: 1.8rem;
      p {
        margin-bottom: 1rem;
      }

      .station-intro, .station-video {
        max-width: $main-max;
        margin: 3rem auto 8rem;
      }
      .exhibit {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        margin: 4rem 2rem 12rem 2rem;
        @media screen and (max-width: $large) {
          gap: 1rem;
          margin: 2rem 1rem;
        }
        @media screen and (max-width: $medium) {
          grid-template-columns: 1fr;
          gap: 1rem;
          margin: 4rem 1rem;
        }
        .exhibit-text {
          max-width: 36rem;
          font-size: 1.25rem;
          line-height: 1.5rem;
          h3 {
            margin: 0rem 0 2rem 0;
          }
          .caption {
            font-size: .85rem;
            line-height: 1.15rem;
          }
          .extra-level {
            display: flex;
            justify-content: flex-start;
            gap: 2rem;
            img {
              display: block;
              height: 4rem;
              margin-top: 2rem;
              cursor: pointer;
              &:hover {
                opacity: .75;
              }
            }
            .hint {
              margin-top: 2rem;
              height: 4rem;
              line-height: 4rem;
              font-size: 2.5rem;
              opacity: .75;
              display: none;
            }
            &:hover .hint {
              display: block;
            }
          }
        }
        .exhibit-medium {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding: 0 1rem;
          @media screen and (max-width: $medium) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
          }
          // für das single-column Layout
          h3 {
            margin: 0rem 0 2rem 0;
          }
          .caption {
            font-size: .85rem;
            line-height: 1.15rem;
          }
          .image-container {
            position: relative;
            display: inline-block;
            margin: 0 auto;
            img.repro {
              display: block;
            }
            .icon {
              position: absolute;
              bottom: 1.5rem;
              left: 1.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 1.8rem;
              height: 1.8rem;
              border: 1px solid $icon-border;
              border-radius: 50%;
              background-color: $icon-bg;
              opacity: .75;
              &:hover {
                opacity: 1;
              }
              img {
                width: 1.25rem;

              }
            }
          }
        }
      }
    }
    &.guest-book {
      .guest-book-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 2rem;
          &.reduce {
            transform: rotate(180deg);
          }
        }
      }
      .guest-book-body {
        font-size: 1.25rem;
        form {
          max-width: 90%;
          .form-element {
            &.website {
              display: none !important;
            }
            margin-bottom: .5rem;
            label, input {
              display: block;
              max-width: 100%;
            }
            label {
              font-size: .85rem;
              line-height: 1.25rem;
            }
            input, textarea {
              width: 30rem;
              max-width: 100%;
            }
            textarea {
              
            }
            button.submit-button {
              padding: .25rem .4rem;
              background: #fff;
              border: 1px solid #333;
              line-height: 1.25rem;
              &:hover {
                background: rgba(0,0,0,.125);
              }
            }
          }
        }
      }
      .comments {
        h3 {
          margin: 2rem 0;
        }
        .comment {
          margin: 1.5rem 0;
          .comment-content {
            font-size: 1rem;
            margin-top: 1rem;
          }
          .comment-author {
            font-size: 1rem;
            font-style: italic;
            text-align: right;
            padding-top: .25rem;
            border-top: 1px solid #333;
          }
        }

      }
    }
    &.resources,
    &.imprint {
      max-width: 90vw;
      font-size: 1.25rem;
      overflow-x: hidden;
      ul {
        font-size: 1.25rem;
      }
      .resources-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6rem;

        margin: 0 auto;
        @media screen and (max-width: $large) {
          display: block;      
        }
        .logos {
          .logo-container.double {
            display: flex;
            justify-content: space-between;
            gap: 2rem;
            a {
              width: 50%;
              img {
                display: inline-block;
                width: 100%;
                max-width: 20rem;
                margin: 2rem 0;
              }
            }
          }
          .logo-container.single {
            display: flex;
            justify-content: space-between;
            gap: 3rem;
            margin-top: 1rem;
            @media screen and (max-width: $small) {
              gap: 1.5rem;     
            }
            a {
              img {
                display: inline-block;
                width: 6rem;
                margin: .25rem 0;
                @media screen and (max-width: $small) {
                  width: 4rem;     
                }
              }
            }
            p {
              flex-grow: 1;
              font-size: 1.25rem;
            }
          }

        }
      } 
    }    
  }
  p {
    margin-bottom: .5rem;
    // font-size: 1rem;
    line-height: 1.25;
  }
  ul {
    margin-left: 1rem;
    font-size: 1rem;
  }
}
.curators-intro,
.statements {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.curatorial,
.statement {
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 1rem;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      display: block;
      width: 56px;
      opacity: .75;
    }
    &:hover {
      background-color: rgba(0,0,0,0);
      img {
        opacity: 1;
      }
    }
  }
}
@keyframes title-bg {
    0% {
      transform: scale(2.4);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}
@keyframes sb-1-animation {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}
@keyframes sb-2-animation {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}
@keyframes sb-3-animation {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}
.separator {
      border-bottom: 1px solid #333;
      max-width: 75vw;
      margin: 0 auto;
    }
</style>
